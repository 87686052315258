import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { BG2 } from "../../images";

export const SectionBanner = styled.div`
  position: relative;
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${BG2});
  background-size: cover;
  @media screen and (max-width: 991px) {
    padding: 20px;
  }
  h1 {
    color: #fff;
  }
`;

export const ImagUser = styled.div`
  overflow: hidden;
  position: absolute;
  top: -35px;
  left: 135px;
  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
`;

export const LoginBox = styled.div`
  position: absolute;
  //padding: 75px 20px;
  padding-top: 75px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 450px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);

  h3 {
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: 500;
    text-align: center;
  }

  h4 {
    font-size: 1rem;
    margin-top: 10px;
    color: #fff;
    font-weight: 200;
    text-align: center;
  }
  p {
    color: #fff;
    text-align: justify;
  }

  span {
    color: #ff0157;
  }

  @media screen and (max-width: 991px) {
    padding-top: 75px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 30px;
  }
`;

export const FormLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  input {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #555;
    padding: 10px;
    outline: none;
    font-size: 16px;
    font-weight: 300;

    border-radius: 2px;
    //margin-right: 10px;

    &::placeholder {
      color: #111;
    }

    @media screen and (max-width: 820px) {
      width: 100%;
      margin: 0 0 16px 0;
    }
  }
  button {
    font-size: 1rem;
    color: #fff;
    background: #ff0157;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    transition: 0.5s;
    max-width: 150px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    padding: 10px 10px;
    margin-top: 10px;
    :hover {
      letter-spacing: 6px;
    }
  }
`;

export const TextLink = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const LinkRegister = styled(Link)`
  color: ${(props) => props.color};
  text-decoration: none;
`;

export const DisplayError = styled.div`
  background: #e797b1;
  display: block;
  width: 50%;
  height: auto;
  position: absolute;
  top: 120px;
  text-align: center;
  border-radius: 20px;

  padding: 5px;

  span {
    color: #fff;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 820px) {
    width: 80%;
  }
`;
