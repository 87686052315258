import React, { useState } from "react";
import styled from "styled-components";

const FilterForm = styled.div`
  padding: 50px 50px;
  background: #fff;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin-top: 50px;

  h3 {
    color: #111;
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: 500;
  }

  @media screen and (max-width: 991px) {
    padding: 35px 40px;
    margin-bottom: 30px;
  }
`;

const DataFilter = styled.ul`
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;

  @media screen and (max-width: 991px) {
    padding: 35px 40px;
    margin-bottom: 30px;
  }
`;

const FilterItem = styled.li`
  list-style: none;
  background: #ff0157;
  color: #111;
  padding: 8px 20px;
  margin: 5px;
  letter-spacing: 1px;
  cursor: pointer;
  &:hover {
    background-color: #f11255;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

const ButtonToggle = styled(FilterItem)`
  opacity: 0.7;
  ${({ active }) => active && `opacity: 1; `}
`;

const Product = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;

  @media screen and (max-width: 991px) {
    margin-top: 20px;
  }
`;

const Box = styled.div`
  width: 340px;
  margin: 20px;
  border: 15px solid #fff;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 991px) {
    margin: 10px;
  }
`;

export const ImgBx = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 991px) {
    margin-top: 20px;
    height: 260px;
  }
`;
export const TextBox = styled.div`
  padding: 15px 10px;
  h3 {
    font-size: 0.9rem;
    font-weight: 400;
    color: #111;
  }
  p {
    color: #111;
  }
  span {
    color: #ff0157;
    font-weight: 400;
    //font-size: 1rem;
  }
`;

const FilterGallery = ({ ListDataFilter, ListDataProduct }) => {
  const [active, setActive] = useState(0);

  function ToggleGroup() {
    return (
      <DataFilter>
        {ListDataFilter.map((data, index) => (
          <ButtonToggle
            active={active === index}
            onClick={() => setActive(index)}
          >
            {data.name}
          </ButtonToggle>
        ))}
      </DataFilter>
    );
  }

  return (
    <FilterForm>
      <h3>Send Message</h3>
      <ToggleGroup />
      <Product>
        {active === 0 ? (
          <>
            {ListDataProduct.map((data, index) => {
              return (
                <>
                  <Box filter={data.idfilter}>
                    <ImgBx>
                      <img src={data.img} alt={index} />
                    </ImgBx>
                    <TextBox>
                      <h3>{data.Nome}</h3>
                      <h3>
                        <span>Hidratos: </span>
                        {data.HidratosCarbono}
                      </h3>
                    </TextBox>
                  </Box>
                </>
              );
            })}
          </>
        ) : (
          <>
            {ListDataProduct.filter((catal) => catal.idfilter === active).map(
              (catal, index) => {
                return (
                  <>
                    <Box filter={catal.idfilter}>
                      <ImgBx>
                        <img src={catal.img} alt={catal.id} />
                      </ImgBx>
                      <TextBox>
                        <h3>{catal.Nome} </h3>
                        <h3>
                          <span>Hidratos: </span>
                          {catal.HidratosCarbono}
                        </h3>
                      </TextBox>
                    </Box>
                  </>
                );
              }
            )}
          </>
        )}
      </Product>
    </FilterForm>
  );
};

export default FilterGallery;
