import React from "react";
import styled from "styled-components";

import { TitleMenu, TitleText } from "../../globalStyles";

import { BG3 } from "../../images";
import { FilterGallery } from "../../components";

const SectionCalculator = styled.div`
  padding: 20px 300px;
  background: url(${BG3});
  background-size: cover;
  background-position: 50%;

  @media screen and (max-width: 991px) {
    padding: 10px;
  }
`;

const Calculator = () => {
  return (
    <>
      <SectionCalculator>
        <TitleMenu>
          <TitleText>
            <h2>
              <span>C</span>alculator
            </h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
          </TitleText>
        </TitleMenu>

        <FilterGallery />
      </SectionCalculator>
    </>
  );
};

export default Calculator;
