import React from "react";
import styled from "styled-components";
import { TitleMenu, TitleText } from "../../globalStyles";
import { withRouter } from "react-router";
import { BG3 } from "../../images";

import { FilterGallery } from "../../components";
import { ListDataFilter, ListDataProduct } from "./Data";

import { BG } from "../../images";

const SectionBanner = styled.div`
  position: relative;
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${BG});
  background-size: cover;
  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;
const SectionCalculator = styled.div`
  padding: 20px 100px;
  background: url(${BG3});
  background-size: cover;
  background-position: 50%;

  @media screen and (max-width: 991px) {
    padding: 10px;
  }
`;

const CalculadoraPage = () => {
  return (
    <>
      <SectionBanner />
      <SectionCalculator>
        <TitleMenu>
          <TitleText>
            <h2>
              <span>C</span>alculator
            </h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
          </TitleText>
        </TitleMenu>
        <FilterGallery
          ListDataProduct={ListDataProduct}
          ListDataFilter={ListDataFilter}
        />
      </SectionCalculator>
    </>
  );
};

export default withRouter(CalculadoraPage);
