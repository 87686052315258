import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import { AuthProvider } from "./AuthContext";

import { AuthProvider } from "./Auth";

import PrivateRouter from "./PrivateRouter";

import GlobalStyle from "./globalStyles";

import { Navbar, Signup2, Login2, Profiles, Footer } from "./components";
import { calculadoraPage, HomePage, TestimonialsPage, FootPage } from "./pages";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <AuthProvider>
      <Router>
        <GlobalStyle />
        <ScrollToTop />
        <Navbar />
        <Switch>
          /** Authentication */
          <Route path="/login" component={Login2} />
          <Route path="/signup" component={Signup2} />
          /**Section */
          <Route path="/" exact component={HomePage} />
          /**Page */
          <PrivateRouter path="/profile" component={Profiles} />
          <PrivateRouter path="/calc" exact component={calculadoraPage} />
          <PrivateRouter path="/ali" exact component={FootPage} />
          <PrivateRouter
            path="/testimonials"
            exact
            component={TestimonialsPage}
          />
        </Switch>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
