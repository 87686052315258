import styled, { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";

const GlobalStyle = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    //scroll-behavior: smooth;
}
`;

export const TitleText = styled.div`
  h2 {
    color: #111;
    font-size: 1.5rem;
    font-weight: 300;
  }
  span {
    color: #ff0157;
    font-weight: 700;
    font-size: 2.5rem;
  }

  p {
    text-align: justify;
  }

  a {
    font-size: 1rem;
    color: #fff;
    background: #ff0157;
    display: inline-block;
    padding: 10px 30px;
    margin-top: 20px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    transition: 0.5s;
    :hover {
      letter-spacing: 6px;
    }
  }
`;

export const ImgBx = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 991px) {
    margin-top: 20px;
    height: 260px;
  }
`;

export const TextBox = styled.div`
  padding: 15px 0 5px;
  h3 {
    font-weight: 400;
    color: #111;
  }
  p {
    color: #111;
  }
`;

export const TitleMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export const ExpertContent = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 40px;
`;

export const ExpertBox = styled.div`
  width: 250px;
  margin: 15px;
`;

export const LinkSignup = styled(Link)`
  font-size: 1rem;
  color: #fff;
  background: #ff0157;
  display: inline-block;
  padding: 10px 30px;
  margin-top: 20px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  transition: 0.5s;
  :hover {
    letter-spacing: 6px;
  }
`;

export default GlobalStyle;
