export const ListDataFilter = [
  { id: 0, name: "All" },
  { id: 1, name: "Agua" },
  { id: 2, name: "Camera" },
  { id: 3, name: "Watch" },
  { id: 4, name: "Shoe" },
  { id: 5, name: "Headphone" },
  { id: 6, name: "Agua" },
];

export const ListDataProduct = [
  {
    codigo: 1,
    Nome: "Água, rede pública de abastecimento",
    Nivel1: "Água e bebidas à base de água",
    Nivel2: "Água potável",
    Nivel3: "Água não engarrafada",
    HidratosCarbono: 0,
    idfilter: 1,
    img: require("../../images/ListProducts/Agua/Agua_rede_pública.jpg")
      .default,
  },

  {
    codigo: 2,
    Nome: "Água e bebidas à base de água (Pizões-Moura)",
    Nivel1: "Água e bebidas à base de água",
    Nivel2: "Água potável",
    Nivel3: "Água engarrafada",
    HidratosCarbono: 0,
    idfilter: 1,
    img: require("../../images/ListProducts/Agua/agua-castello.png").default,
  },

  {
    codigo: 3,
    Nome: "Água mineral natural gasocarbónica (Pedras Salgadas)",
    Nivel1: "Água e bebidas à base de água",
    Nivel2: "Água potável",
    Nivel3: "Água engarrafada",
    HidratosCarbono: 0,
    idfilter: 1,
    img: require("../../images/ListProducts/Agua/agua-pedras-salgadas.jpg")
      .default,
  },

  {
    codigo: 4,
    Nome: "Água mineral natural (Luso)",
    Nivel1: "Água e bebidas à base de água",
    Nivel2: "Água potável",
    Nivel3: "Água engarrafada",
    HidratosCarbono: 0,
    idfilter: 2,
    img: require("../../images/Menu/menu4.jpg").default,
  },

  {
    codigo: 5,
    Nome: "Água mineral natural gaseificada (Vimeiro)",
    Nivel1: "Água e bebidas à base de água",
    Nivel2: "Água potável",
    Nivel3: "Água engarrafada",
    HidratosCarbono: 0,
    idfilter: 3,
    img: require("../../images/Menu/menu5.jpg").default,
  },
];
