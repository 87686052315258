import React from "react";
import styled from "styled-components";

import {
  TitleMenu,
  TitleText,
  ImgBx,
  TextBox,
  ExpertContent,
  ExpertBox,
} from "../../globalStyles";

import { BG3 } from "../../images";

const SectionContact = styled.div`
  padding: 120px;
  background: url(${BG3});
  background-size: cover;

  @media screen and (max-width: 991px) {
    padding: 10px;
  }
`;

const ContactForm = styled.div`
  padding: 75px 50px;
  background: #fff;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin-top: 50px;

  h3 {
    color: #111;
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: 500;
  }

  @media screen and (max-width: 991px) {
    padding: 35px 40px;
    margin-bottom: 30px;
  }
`;

const FormInput = styled.input`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #555;
  padding: 10px;
  outline: none;
  font-size: 16px;
  font-weight: 300;

  border-radius: 2px;
  //margin-right: 10px;

  &::placeholder {
    color: #111;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    margin: 0 0 16px 0;
  }
`;

const FormTextArea = styled.textarea`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #555;
  padding: 10px;
  outline: none;
  font-size: 16px;

  border-radius: 2px;
  // margin-right: 10px;

  resize: none;
  &::placeholder {
    color: #111;
  }
`;

const FormBtnArea = styled.input.attrs({
  type: "submit",
  value: "Send",
})`
  font-size: 1rem;
  color: #fff;
  background: #ff0157;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  transition: 0.5s;
  max-width: 150px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  padding: 10px 10px;
`;

const Contact = () => {
  return (
    <>
      <SectionContact id="contact">
        <TitleMenu>
          <TitleText>
            <h2>
              <span>C</span>ontact Us
            </h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
          </TitleText>
        </TitleMenu>

        <ContactForm>
          <h3>Send Message</h3>
          <FormInput name="name" type="text" placeholder="Your Name" />
          <FormInput name="email" type="email" placeholder="Your Email" />
          <FormTextArea placeholder="Texto" />
          <FormBtnArea />
        </ContactForm>
      </SectionContact>
    </>
  );
};

export default Contact;
