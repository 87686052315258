import React from "react";
import styled from "styled-components";
import { TitleText, TitleMenu, ImgBx, TextBox } from "../../globalStyles";

import { Menu1, Menu2, Menu3 } from "../../images";

const SectionMenu = styled.div`
  padding: 120px;

  @media screen and (max-width: 991px) {
    padding: 60px;
  }
`;

const MenuContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  margin-top: 40px;

  @media screen and (max-width: 991px) {
    margin-top: 20px;
  }
`;

const Box = styled.div`
  width: 340px;
  margin: 20px;
  border: 15px solid #fff;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 991px) {
    margin: 10px;
  }
`;

const Menu = () => {
  return (
    <>
      <SectionMenu id="menu">
        <TitleMenu>
          <TitleText>
            <h2>
              Our <span>M</span>enu
            </h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
          </TitleText>
        </TitleMenu>

        <MenuContent>
          <Box>
            <ImgBx>
              <img src={Menu1} alt="" />
            </ImgBx>
            <TextBox>
              <h3>Special Salad</h3>
            </TextBox>
          </Box>
          <Box>
            <ImgBx>
              <img src={Menu2} alt="" />
            </ImgBx>
            <TextBox>
              <h3>Special Soup</h3>
            </TextBox>
          </Box>
          <Box>
            <ImgBx>
              <img src={Menu3} alt="" />
            </ImgBx>
            <TextBox>
              <h3>Special Pasta</h3>
            </TextBox>
          </Box>
          <Box>
            <ImgBx>
              <img src={Menu1} alt="" />
            </ImgBx>
            <TextBox>
              <h3>Special Salad</h3>
            </TextBox>
          </Box>
          <Box>
            <ImgBx>
              <img src={Menu2} alt="" />
            </ImgBx>
            <TextBox>
              <h3>Special Soup</h3>
            </TextBox>
          </Box>
          <Box>
            <ImgBx>
              <img src={Menu3} alt="" />
            </ImgBx>
            <TextBox>
              <h3>Special Pasta</h3>
            </TextBox>
          </Box>
        </MenuContent>
        <TitleMenu>
          <TitleText>
            <a href="#">View More</a>
          </TitleText>
        </TitleMenu>
      </SectionMenu>
    </>
  );
};

export default Menu;
