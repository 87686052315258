import React from "react";
import styled from "styled-components";
import { AlimentosDB } from "./Data";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // background: #344;
`;

const SelectedBox = styled.div`
  background: #d61f1f;
  width: 100%;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #a7e90d;
  width: 100%;
`;

const OptionInput = styled.div`
  //background: #111;
  color: #111;
  margin-right: 5px;
`;

const Radio = styled.input.attrs((props) => ({
  type: "radio",
  name: "category",
  id: props.id,
}))`
  font-size: 1rem;
  color: #fff;
  margin-right: 5px;
`;

const SelectBox = () => {
  return (
    <Container>
      <SelectedBox>
        <h1>Select Category</h1>

        <OptionContainer>
          {AlimentosDB.map((data, index) => {
            return (
              <OptionInput>
                <Radio id={index} />
                <label for={index}>{data.Nome}</label>
              </OptionInput>
            );
          })}
        </OptionContainer>
      </SelectedBox>
    </Container>
  );
};

export default SelectBox;
