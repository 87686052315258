import React, { useState, useContext } from "react";
import styled from "styled-components";
import { FaBars, FaTimes, FaArrowUp, FaArrowDown } from "react-icons/fa";

import { AguaCastello } from "../../images";

const SelectFootMenu = ({ data }) => {
  const [showmore, setShowmore] = useState(false);
  const [search, setSearch] = useState("");

  const handlerShowmore = () => setShowmore(!showmore);

  const filtercoin = data.filter((coin) =>
    coin.Nomedoalimento.toLowerCase().includes(search.toLocaleLowerCase())
  );

  return (
    <AllCoin showmore={showmore}>
      <HeaderSelect>
        <ButtonShow onClick={handlerShowmore}>
          {showmore ? <FaTimes /> : <FaBars />}
        </ButtonShow>

        <Search>
          <form>
            <input
              type="text"
              placeholder="Search here for your Foot "
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </form>
        </Search>
      </HeaderSelect>

      <SectionAllCoin>
        <>
          {filtercoin.map((coin) => {
            return (
              <Card key={coin.id}>
                <CardHead>
                  <ImagemArea>
                    <img src={coin.image} alt="iamge" />
                  </ImagemArea>
                </CardHead>
                <CardContext>
                  <Description>{coin.Nomedoalimento}</Description>
                  <span>Hid. Carbono_g: {coin.Hidratosdecarbono_g}</span>
                  <span>Colesterol_mg: {coin.Colesterol_mg}</span>
                  <span>Proteinas_g: {coin.Proteinas_g}</span>
                </CardContext>
              </Card>
            );
          })}
        </>
      </SectionAllCoin>
    </AllCoin>
  );
};

export default SelectFootMenu;

const AllCoin = styled.div`
  display: flex;
  color: #000;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-image: linear-gradient(
    -225deg,
    #e71b58 0%,
    #e71b58 48%,
    #121 100%
  );
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  //border-radius: 5px;
  @media screen and (max-width: 691px) {
    margin-top: 60px;
    margin-left: 1px;
    margin-right: 1px;
  }
`;

const SectionAllCoin = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const HeaderSelect = styled.div`
  display: flex;
  font-size: 1rem;
  justify-content: center;
`;

const ButtonShow = styled.div`
  position: absolute;
  left: 60px;
  cursor: pointer;
  color: #fff;

  @media screen and (max-width: 691px) {
    left: 20px;
  }
`;

const Search = styled.div`
  margin-top: 0;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 15px;
  font-size: 0.5rem;

  input {
    padding-left: 16px;
    width: 350px;
    height: 50px;
    box-sizing: border-box;
    border: transparent;
    border-bottom: 1px solid #fff;
    font-size: 16px;
    color: #111;
    background-color: transparent;
    background-position: right 5px center;
    padding: 10px 30px 10px 10px;
    transition: width 0.4s ease-in-out;
    text-align: center;
    color: #e2e2e2;
    ::placeholder {
      color: #fff;
    }
  }
`;

const Card = styled.div`
  width: 200px;
  height: auto;
  margin: 5px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background: #f3e7e7;
  }
`;

const CardHead = styled.div`
  //margin: auto;
  padding-top: 2px;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100px;
  //background: #000;
  //color: #fff;

  span {
    margin-top: 5px;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
`;

const ImagemArea = styled.div`
  width: 100%;
  height: 100%;
  //background: #fff;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CardContext = styled.div`
  //margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding-left: 10px;
  padding-right: 10px;
  span {
    margin-top: 10px;
    font-size: 0.8rem;
    font-weight: 600;
  }
`;

const Description = styled.div`
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 5px;
  font-size: 0.6rem;
  text-transform: uppercase;
`;
