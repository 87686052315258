import React from "react";
import styled from "styled-components";
import Avatar from "@material-ui/core/Avatar";

const PostSection = styled.div`
  max-width: 500px;
  background-color: #fff;
  border: 1px solid lightgray;
  margin-bottom: 45px;

  img {
    width: 100%;
    object-fit: contain;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }
`;

const PostHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`;

const PostAvatar = styled.div`
  margin-right: 10px;
`;

const PostText = styled.h2`
  font-weight: normal;
  padding: 20px;
  h2 {
    color: #111;
    font-size: 1rem;
    font-weight: 300;
  }
  span {
    color: #ff0157;
    font-weight: 500;
    font-size: 1rem;
  }

  p {
    text-align: justify;
    color: #fff;
  }
`;

const TestimonialsPost = ({ username, imageUrl, caption }) => {
  return (
    <PostSection>
      {/** header avatar username*/}
      <PostHeader>
        <PostAvatar>
          <Avatar alt={username} src="/static/images/avatar/1.jpg" />
        </PostAvatar>
        {username}
      </PostHeader>

      {/** image */}
      <img src={imageUrl} alt="" />

      {/*username + caption */}
      <PostText>
        <h2>
          <span>{username}</span> {caption}
        </h2>
      </PostText>
    </PostSection>
  );
};

export default TestimonialsPost;
