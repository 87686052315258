import React from "react";
import styled from "styled-components";
import { TitleText, ImgBx } from "../../globalStyles";

import { Img1 } from "../../images";

const SectionAbout = styled.div`
  padding: 120px;

  @media screen and (max-width: 991px) {
    padding: 60px;
  }
`;

const Row = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

const Col50 = styled.div`
  position: relative;
  width: 48%;

  @media screen and (max-width: 991px) {
    position: relative;
    width: 100%;
  }
`;

const About = () => {
  return (
    <>
      <SectionAbout id="about">
        <Row>
          <Col50>
            <TitleText>
              <h2>
                <span>A</span>bout Us
              </h2>
              <p>
                Embora as evidências mais recentes sugiram que o diabetes tipo 2
                pode ser revertido, tanto o diabetes tipo 1 quanto o tipo 2
                agora devem ser vistos como doenças crônicas que requerem
                autogerenciamento contínuo.<br></br>{" "}
                <strong>Diabetes tipo 1</strong> Pessoas com diabetes tipo 1
                precisam de injeções diárias de insulina para controlar seus
                níveis de glicose no sangue. Sem acesso à insulina, eles
                morreriam. O tratamento eficaz do diabetes tipo 1 também requer
                controle regular da glicose no sangue e um estilo de vida
                saudável. Mais de 1 milhão (1.110.100) de crianças e adolescente
                menores de 20 anos tem diabetes. <br></br>
                não existe uma intervenção efetiva e segura para previr a
                diabetes de tipo 1. Sem embargo, existe certa evidencia de que à
                uma alta taxa de crescimento nas crianças, que leva recomendar
                um estilo de vida mais saudável para previr a sobrealimentação e
                o sedentarismo.
              </p>
            </TitleText>
          </Col50>
          <Col50>
            <ImgBx>
              <img src={Img1} alt="" />
            </ImgBx>
          </Col50>
        </Row>
      </SectionAbout>
    </>
  );
};

export default About;
