import React from "react";

import {
  Banner,
  About,
  Menu,
  Expert,
  Testimonials,
  Contact,
} from "../../components";

const HomePage = () => {
  return (
    <>
      <Banner />
      <About />
      <Menu />
      <Expert />
      <Testimonials />
      <Contact />
    </>
  );
};

export default HomePage;
