import React, { useState } from "react";
import styled from "styled-components";
import { IconContext } from "react-icons/lib";
import { FaTimes, FaBars } from "react-icons/fa";

import { BannerAnimate } from "../../components";

const Header = styled.div`
  //position: fixed;
  position: ${({ styckyheader }) => (styckyheader ? "sticky" : "fixed")};
  background: ${({ styckyheader }) => (styckyheader ? "#fff" : "none")};
  box-shadow: ${({ styckyheader }) =>
    styckyheader ? "none" : "0 5px 20px rgba(0, 0, 0, 0.05)"};

  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 100px;
  transition: 0.5s;

  @media screen and (max-width: 991px) {
    padding: 20px 40px;
  }
`;

const Logo = styled.a`
  color: ${({ styckyheader }) => (styckyheader ? "#111" : "#fff")};
  font-size: 2rem;
  //color: #fff;
  font-weight: 700;
  text-decoration: none;
  transition: 0.5s;
  transform-origin: right;
  cursor: pointer;
  transform: ${({ click }) => (click ? "scale(0)" : "none")};
  transform-origin: ${({ click }) => (click ? "left" : "right")};
  z-index: 2;
`;

const ResponsiveIcon = styled.div`
  position: relative;
  display: block;
  color: ${({ styckyheader }) => (styckyheader ? "#111" : "#fff")};
  font-size: 2rem;
  cursor: pointer;
  z-index: 3;
`;

const Navigation = styled.ul`
  position: fixed;
  display: ${({ click }) => (click ? "flex" : "none")};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;

  background: ${({ styckyheader }) => (styckyheader ? "#151415" : "none")};

  visibility: ${({ click }) => (click ? "visible" : "hidden")};
  opacity: ${({ click }) => (click ? "1" : "0")};
  transition: 0.5s;
  transition-delay: ${({ click }) => (click ? "1s" : "0s")};
  //background: #ff1057;
`;

const Link = styled.li`
  list-style: none;
  margin-left: 30px;
  //z-index: 4;

  a {
    text-decoration: none;
    color: #fff;
    font-size: 2rem;
    font-weight: 300;

    cursor: pointer;

    :hover {
      color: #ff0157;
    }
  }
`;

const NavbarAnimation = () => {
  const [styckyheader, setStyckyheader] = useState(false);
  const [click, setClick] = useState(false);
  const ShowResponsiveBar = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showHeaderScroll = () => {
    if (window.scrollY > 0) {
      setStyckyheader(true);
    } else {
      setStyckyheader(false);
    }
    //console.log(window.scrollY);
  };

  window.addEventListener("scroll", showHeaderScroll);

  return (
    <>
      <Header styckyheader={styckyheader} id="banner">
        <Logo
          href="#"
          styckyheader={styckyheader}
          onClick={closeMobileMenu}
          click={click}
        >
          Watch
        </Logo>

        <ResponsiveIcon styckyheader={styckyheader} onClick={ShowResponsiveBar}>
          {click ? <FaTimes /> : <FaBars />}
        </ResponsiveIcon>

        <Navigation
          onClick={ShowResponsiveBar}
          styckyheader={styckyheader}
          click={click}
        >
          <Link>
            <a href="#banner">Home</a>
          </Link>
          <Link>
            <a href="#about">About</a>
          </Link>
          <Link>
            <a href="#menu">Menu</a>
          </Link>
          <Link>
            <a href="#expert">Expert</a>
          </Link>
          <Link>
            <a href="#testimonials">Testimonials</a>
          </Link>
          <Link>
            <a href="#contact">Contact</a>
          </Link>
        </Navigation>
      </Header>
      <BannerAnimate click={click} />
    </>
  );
};

export default NavbarAnimation;
