import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import {
  TitleMenu,
  ImgBx,
  TextBox,
  ExpertContent,
  ExpertBox,
} from "../../globalStyles";

import { BG2, Testi1, Testi2, Testi3 } from "../../images";

const SectionTestimonial = styled.div`
  padding: 120px;
  background: url(${BG2});
  background-size: cover;
  @media screen and (max-width: 991px) {
    padding: 60px;
  }
`;

const TitleTextWhite = styled.div`
  h2 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 300;
  }
  span {
    color: #ff0157;
    font-weight: 700;
    font-size: 2.5rem;
  }

  p {
    text-align: justify;
    color: #fff;
  }
`;

const TestimonialsTextBox = styled.div`
  text-align: center;

  h3 {
    margin-top: 20px;
    font-size: 1rem;
    color: #ff0157;
    font-weight: 600;
  }
  p {
    color: #666;
    font-style: italic;
  }
`;

const TestimonialsBox = styled.div`
  width: 340px;
  margin: 20px;
  padding: 40px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 991px) {
    margin: 10px;
    padding: 20px;
    width: 440px;
  }
`;

export const TestimonialsImgBx = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  border-radius: 50%;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const LinkTestimonials = styled(Link)`
  font-size: 1rem;
  color: #fff;
  background: #ff0157;
  display: inline-block;
  padding: 10px 30px;
  margin-top: 20px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  transition: 0.5s;
  :hover {
    letter-spacing: 6px;
  }
`;

const Testimonials = () => {
  return (
    <>
      <SectionTestimonial id="testimonials">
        <TitleMenu>
          <TitleTextWhite>
            <h2>
              They <span>S</span>aid About Us
            </h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
            <LinkTestimonials to="/testimonials">view more</LinkTestimonials>
          </TitleTextWhite>
        </TitleMenu>
        <ExpertContent>
          <TestimonialsBox>
            <TestimonialsImgBx>
              <img src={Testi1} alt="" />
            </TestimonialsImgBx>
            <TestimonialsTextBox>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus
                iste consequuntur molestiae nam? Nobis at laboriosam .
              </p>
              <h3>Someone famous</h3>
            </TestimonialsTextBox>
          </TestimonialsBox>
          <TestimonialsBox>
            <TestimonialsImgBx>
              <img src={Testi2} alt="" />
            </TestimonialsImgBx>
            <TestimonialsTextBox>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus
                iste consequuntur molestiae nam? Nobis at laboriosam .
              </p>
              <h3>Someone famous</h3>
            </TestimonialsTextBox>
          </TestimonialsBox>
          <TestimonialsBox>
            <TestimonialsImgBx>
              <img src={Testi3} alt="" />
            </TestimonialsImgBx>
            <TestimonialsTextBox>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Natus
                iste consequuntur molestiae nam? Nobis at laboriosam .
              </p>
              <h3>Someone famous</h3>
            </TestimonialsTextBox>
          </TestimonialsBox>
        </ExpertContent>
      </SectionTestimonial>
    </>
  );
};

export default Testimonials;
