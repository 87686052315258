// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyCDLBaH8IxUK34eKisIP7GoCR-lrUsAWx4",
  authDomain: "diabeteweb.firebaseapp.com",
  projectId: "diabeteweb",
  storageBucket: "diabeteweb.appspot.com",
  messagingSenderId: "1072050901047",
  appId: "1:1072050901047:web:5d35a3c37e6830b93c6fb2",
  measurementId: "G-LRC7VPKQGQ",
});

//export default db;
const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const storage = firebaseApp.storage();

export { db, auth, storage };
