import React from "react";
import styled from "styled-components";
import {
  TitleText,
  TitleMenu,
  ImgBx,
  TextBox,
  ExpertContent,
  ExpertBox,
} from "../../globalStyles";

import { Expert1, Expert2, Expert3, Expert4 } from "../../images";

const SectionExpert = styled.div`
  padding: 120px;

  @media screen and (max-width: 991px) {
    padding: 60px;
  }
`;

const Expert = () => {
  return (
    <>
      <SectionExpert id="expert">
        <TitleMenu>
          <TitleText>
            <h2>
              Our Kitchen <span>E</span>xpert
            </h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
          </TitleText>
          <ExpertContent>
            <ExpertBox>
              <ImgBx>
                <img src={Expert1} alt="" />
              </ImgBx>
              <TextBox>
                <h3>Someone Famous</h3>
              </TextBox>
            </ExpertBox>
            <ExpertBox>
              <ImgBx>
                <img src={Expert2} alt="" />
              </ImgBx>
              <TextBox>
                <h3>Someone Famous</h3>
              </TextBox>
            </ExpertBox>
            <ExpertBox>
              <ImgBx>
                <img src={Expert3} alt="" />
              </ImgBx>
              <TextBox>
                <h3>Someone Famous</h3>
              </TextBox>
            </ExpertBox>
            <ExpertBox>
              <ImgBx>
                <img src={Expert4} alt="" />
              </ImgBx>
              <TextBox>
                <h3>Someone Famous</h3>
              </TextBox>
            </ExpertBox>
          </ExpertContent>
        </TitleMenu>
      </SectionExpert>
    </>
  );
};

export default Expert;
