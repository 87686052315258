import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { BG } from "../../images";

const SectionBanner = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${BG});
  background-size: cover;
  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;

const LinkBanner = styled(Link)`
  font-size: 1rem;
  color: #fff;
  background: #ff0157;
  display: inline-block;
  padding: 10px 30px;
  margin-top: 20px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
  transition: 0.5s;
  :hover {
    letter-spacing: 6px;
  }
`;

const Content = styled.div`
  max-width: 900px;
  text-align: center;
  h2 {
    font-size: 5rem;
    color: #fff;
  }
  p {
    font-size: 1rem;
    font-weight: 300;
    color: #fff;
  }
  a {
    font-size: 1rem;
    color: #fff;
    background: #ff0157;
    display: inline-block;
    padding: 10px 30px;
    margin-top: 20px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    transition: 0.5s;
    :hover {
      letter-spacing: 6px;
    }
  }

  @media screen and (max-width: 480px) {
    h2 {
      font-size: 2.2rem;
      color: #fff;
    }
  }
`;

const Banner = () => {
  return (
    <>
      <SectionBanner id="banner">
        <Content>
          <h2>Diabetes com saúde</h2>
          <p>
            Ter diabetes não significa deixar de comer o que queres, só tens de
            saber controlar o que comes. Podes criar teus menus aqui.
          </p>

          <LinkBanner to="/login">Sign Up</LinkBanner>
        </Content>
      </SectionBanner>
    </>
  );
};

export default Banner;
