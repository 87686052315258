import React, { useState, useEffect } from "react";
import styled from "styled-components";
import firebase from "firebase";
import { db } from "../../firebase";

import { TitleMenu, TitleText, LinkSignup } from "../../globalStyles";

import { Testimonials, TestimonialsPost } from "../../components";

import { BG, BG2, AguaCastello } from "../../images";

const SectionBanner = styled.div`
  position: relative;
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${BG});
  background-size: cover;
  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;

const SectionTitle = styled.div`
  padding: 20px;
  @media screen and (max-width: 991px) {
    padding: 10px;
  }
`;

const SectionPost = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background: #fafafa;
`;

const TestimonialsPage = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    db.collection("posts").onSnapshot((snapshot) => {
      setPosts(snapshot.docs.map((doc) => ({ id: doc.id, post: doc.data() })));
    });
  }, []);

  return (
    <>
      <SectionBanner />
      <SectionTitle>
        <TitleMenu>
          <TitleText>
            <h2>
              Send<span> You </span> Testimonial
            </h2>
            <p>Envia teu testimonio. </p>
          </TitleText>
        </TitleMenu>
      </SectionTitle>
      <SectionPost>
        {posts.map(({ id, post }) => {
          return (
            <>
              <TestimonialsPost
                key={id}
                username={post.username}
                imageUrl={post.imageUrl}
                caption={post.caption}
              />
            </>
          );
        })}
      </SectionPost>
    </>
  );
};

export default TestimonialsPage;
