import React from "react";
import styled from "styled-components";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { watch } from "../../images";

const SectionBannerAnimate = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 0 100px;
  display: flex;
  align-items: center;
  background: linear-gradient(#404040, #151415);

  ::before {
    content: "";
    position: absolute;
    top: 0;

    left: ${({ click }) => (click ? 0 : "300px")};
    width: ${({ click }) => (click ? "50%" : "400px")};
    height: ${({ click }) => (click ? "100%" : "320px")};
    background: ${({ click }) => (click ? "#151415" : "#fa215e")};
    z-index: 1;
    transition: 1s;
  }

  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: ${({ click }) => (click ? 0 : "100px")};
    width: ${({ click }) => (click ? "50%" : "400px")};
    height: ${({ click }) => (click ? "100%" : "200px")};
    background: ${({ click }) => (click ? "#151415" : "#aeff0f")};
    z-index: 1;
    transition: 1s;
  }

  @media screen and (max-width: 991px) {
    padding: 40px;
    overflow: hidden;

    ::before {
      top: ${({ click }) => (click ? 0 : "100px")};
      left: ${({ click }) => (click ? 0 : 0)};
      width: ${({ click }) => (click ? "50%" : "170px")};
      height: ${({ click }) => (click ? "100%" : "310px")};
      background: ${({ click }) => (click ? "#151415" : "#fa215e")};
      z-index: 1;
      transition: 1s;
    }

    ::after {
      bottom: 0;
      right: ${({ click }) => (click ? 0 : 0)};
      width: ${({ click }) => (click ? "50%" : "80px")};
      height: ${({ click }) => (click ? "100%" : "60px")};
      background: ${({ click }) => (click ? "#151415" : "#aeff0f")};
      z-index: 1;
      transition: 1s;
    }
  }
`;

const ContentBannerAnim = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s;

  //transform-origin: right;
  transform: ${({ click }) => (click ? "scale(0)" : "none")};
  transform-origin: ${({ click }) => (click ? "left" : "right")};

  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
    margin: 50px 0;
  }
`;

const ContentBoxAnim = styled.div`
  max-width: 600px;
  h2 {
    font-size: 4rem;
    line-height: 1em;
    color: #fff;
    text-transform: uppercase;
  }
  p {
    font-size: 1rem;
    color: #fff;
    margin: 20px 0;
  }
  a {
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    padding: 10px 30px;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.5s;

    :hover {
      letter-spacing: 4px;
      background: #fff;
      color: #111;
    }
  }

  @media screen and (max-width: 991px) {
    max-width: 100%;
    h2 {
      font-size: 2.5rem;
    }
  }
`;

const ContentImgBoxAnim = styled.div`
  img {
    max-width: 100%;
    min-width: 400px;
  }

  @media screen and (max-width: 991px) {
    img {
      max-width: 100%;
      min-height: 200px;
    }
  }
`;

const SocialIconContent = styled.ul`
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: 0.5s;
  // transform-origin: right;

  transform: ${({ click }) => (click ? "scale(0)" : "none")};
  transform-origin: ${({ click }) => (click ? "left" : "right")};
`;

const SocialIconItem = styled.li`
  list-style: none;
  font-size: 2rem;
  color: #111;
  display: inline-block;
  margin-right: 20px;
  filter: invert(1);
  transform: scale(0.6);
  cursor: pointer;
  :hover {
    transform: scale(1);
  }
`;

const BannerAnimate = ({ click }) => {
  return (
    <>
      <SectionBannerAnimate click={click}>
        <ContentBannerAnim click={click}>
          <ContentBoxAnim>
            <h2>
              Future on <br /> your Hand
            </h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam
              facere provident repellat molestiae dolore illo voluptatibus amet
              tempora, sint soluta ex qui eius inventore nostrum earum quidem
              odio? Ipsum, rerum!
            </p>
            <a href="">Buy Now</a>
          </ContentBoxAnim>
          <ContentImgBoxAnim>
            <img src={watch} alt="" />
          </ContentImgBoxAnim>
        </ContentBannerAnim>
        <SocialIconContent click={click}>
          <SocialIconItem>
            <FaFacebook />
          </SocialIconItem>
          <SocialIconItem>
            <FaTwitter />
          </SocialIconItem>
          <SocialIconItem>
            <FaInstagram />
          </SocialIconItem>
        </SocialIconContent>
      </SectionBannerAnimate>
    </>
  );
};

export default BannerAnimate;
