import React, { useState, useContext } from "react";
import { auth } from "../../firebase";
import { AuthContext } from "../../Auth";
import { Expert1 } from "../../images";
import {
  SectionBanner,
  LoginBox,
  ImagUser,
  TextLink,
  LinkRegister,
  DisplayError,
} from "./auth.styles";

const Profiles = () => {
  const [error, setError] = useState("");
  const { currentUser } = useContext(AuthContext);
  // console.log(currentUser);
  async function handleLogout() {
    setError("");
    try {
      await auth.signOut();
    } catch {
      setError("Faild to Log Out");
    }
  }
  return (
    <SectionBanner>
      {error ? (
        <DisplayError>
          <span>{error}</span>
        </DisplayError>
      ) : (
        <></>
      )}
      <LoginBox>
        <ImagUser>
          <img alt="" src={Expert1} />
        </ImagUser>
        <h3>
          Welcome <span>{currentUser.email}</span>
        </h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis illo
          odit asperiores voluptates, at repellat cum aspernatur eius magni,
          quisquam impedit dolor molestias ab, quaerat laborum itaque tempore
          unde minus.
        </p>
        <TextLink>
          <LinkRegister color={"#ff0157"} to="/" onClick={handleLogout}>
            Log Out
          </LinkRegister>
        </TextLink>
      </LoginBox>
    </SectionBanner>
  );
};

export default Profiles;
