import styled from "styled-components";
import React, { useState, useEffect } from "react";

import alimentosAPI from "../../apis/GetApisData";
import { SelectFootMenu } from "../../components";
import { BG } from "../../images";

const FootPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [foots, setFoots] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response2 = await alimentosAPI.get("/", {
        params: {},
      });
      setFoots(response2.data);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <SectionBanner />

      <SelectFootMenu data={foots} />
    </>
  );
};

export default FootPage;

const SectionBanner = styled.div`
  position: relative;
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${BG});
  background-size: cover;
  @media screen and (max-width: 991px) {
    padding: 20px;
  }
`;
